.login-modal {
  .ant-btn > span{
    padding-left: 8px;
  }
  .text {
    margin-top: 2px;
    font-size: 12px;
    color: @primary-color;
    cursor: pointer;
  }

  .login-btn {
    margin-top: 30px;
    width: 160px;
    height: 40px;
    text-align: center;
    border-radius: 20px;
  }

  // .code-button {
  //     border-left: 1px solid #ccc;
  //     padding-left: 12px;
  //     border-radius: 0;
  //   }
  .code-button {
    &::before {
      content: " ";
      display: block;
      width: 1px;
      height: 12px;
      margin-top: 6px;
      background-color: #DDDDDD;
    }
  }

  // 注册样式
  .ant-form-item .ant-input-group .area-code-select {
    line-height: 32px;
    height: 32px;
    width: 25%!important;
    .ant-select-selector {
      line-height: 32px;
      display: block;
      height: 32px;
    }
  }
  .ant-form-item-explain.ant-form-item-explain-error{
    font-size: 12px;
  }
  .dropdown-area-code-select {
    min-width: 200px!important;
    .option-label-item {
      width: 100%;
      .option-label-item-left {
        float: left;
      }
      .option-label-item-right {
        float: right;
      }
      &::before, &::after {
        clear: both;
      }
    }
  }
  .sure{
    text-align: center;
  }
  .tips {
    font-size: 12px;
    color: #333;
    line-height: 17px;
    span {
      font-size: 12px;
      color: @primary-color;
      cursor: pointer;
    }
  }
}

@hack: true;@import "/data/sdic-iguopin-liuxue/src/theme.less";