@import "../../theme.less";

.layout-header {
  width: 100%;
  height: 80px;
  background-color: #272727;
  position: sticky;
  top: 0;
  z-index: 1000;
  .container {
    display: flex;
    align-items: center;
    .logo {
      width: 100px;
      height: 67px;
      object-fit: contain;
      cursor: pointer;
      margin-right:47px;
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      flex: 1;
      .nav {
        display: flex;
        justify-content: space-between;
        width: 437px;
        font-size: 16px;
        a{
          color: #fff;
        }
        .nav-item {
          position: relative;
          padding: 0 12px;
          height: 80px;
          line-height: 80px;
          cursor: pointer;

          &:hover,
          &.active {
            &::after {
              content: "";
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 3px;
              background: @primary-color;
            }
          }
        }
      }
      .language{
        span:hover{
          cursor: pointer;
        }
        .active{
          color: @primary-red;
        }
      }
      .contact {
        font-size: 18px;
        display: flex;
        align-items: center;
        margin-left: 80px;
        .iconfont {
          font-size: 26px;
          color: #fff;
        }
      }

      .user-center {
        .login {
          margin-right: 12px;
          border-color: #E50000;
          background-color: #E50000;
          color:#fff; 
          box-sizing: border-box;
        }
        .register{
          color:#fff; 
          border-color: #E50000;
        }
      }

      .user-login {
        height: 50px;
        // line-height: 50px;
        padding-top: 12px;
        text-align: center;
        color:#fff;
        // background: #FFFFFF;

        .user-name {
          cursor: pointer;
          font-size: 16px;
          font-weight: 400;
          color:#fff;
          line-height: 22px;

        }
      }
    }
  }
}
//.ant-dropdown-menu-title-content {
  //&:hover {
    //background: rgba(206, 58, 58, 0.1);
    //padding:0;
  //}
//}
.user-item {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
  color: #333333;

  &:hover {
    color: @primary-color;
  }
}

@hack: true;@import "/data/sdic-iguopin-liuxue/src/theme.less";