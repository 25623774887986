@import "./node_modules/antd/dist/antd.less";
@import "./theme.less";

body {
  margin: 0;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //  sans-serif;
  font-family: PingFangSC-Medium, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1230px;
}

svg {
  vertical-align: baseline;
}

ul {
  margin-bottom: 0;
  padding-left: 0;
}

ul li {
  list-style: none;
}

.container {
  width: 1200px;
  box-sizing: border-box;
  // padding-left: 15px;
  // padding-right: 15px;
  margin: 0 auto;
}

.left-nav-float-container {
  overflow: hidden;

  .personal-left-nav {
    float: left;
    box-sizing: border-box;
  }

  .right-content {
    float: left;
    margin-left: 30px;
    width: calc(100% - 204px - 30px);
    box-sizing: border-box;
  }
}

.substring {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.substring2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.clear {
  clear: both;
  height: 0px;
  font-size: 0px;
  line-height: 0px;
}

.hidden {
  display: none;
}

.border-shadow:hover {
  box-shadow: #ccc 0 0 7px;
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  background: none;
}

.iconfont {
  color: #999;
  margin-right: 5px;
}

.ant-pagination {
  text-align: center;
  margin-top: 50px;
}

.ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content {
  margin-top: 20px;
  margin-left: 0;
}

.braft-output-content img,
.braft-output-content video {
  width: 100%;
}

.font-primary {
  color: @primary-color;
}

.font-gray {
  color: @font-gray;
}

.font-light-gray {
  color: @font-light-gray;
}

.ant-modal-header {
  //background: @bg-gray;
}

.ant-modal-title {
  //font-size: 20px;
  //text-align: center;
}

.ant-modal-footer {
  text-align: center;
}

input{
  background: none;
  outline: none;
  border: 0px;
}

.tuition-box{
  box-shadow: 0px 3px 7px 0px rgba(168, 168, 168, 0.5);
  padding: 40px;
  border-radius: 8px;
  margin-top: -18%;
  background-color: #fff;
}
@hack: true;@import "/data/sdic-iguopin-liuxue/src/theme.less";