.new-footer {
  background-color: #272727;
  color: #fff;
  padding: 59px 83px 40px 171px;

  .footer-top {
    display: flex;
    align-items: center;
    a {
      color: #fff;
      display: block;
    }
    img {
      width: 202px;
      height: 72px;
      margin-right: 55px;
    }
    .top-item {
      padding: 0 30px;
      border-right: 1px solid #fff !important;
    }
    .top-item-last {
      padding: 0 30px;
    }
    .top-item-last:hover {
      cursor: pointer;
    }
  }
  .footer-content {
    margin-top: 27px;
    display: flex;
    justify-content: space-between;
    .content-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .content-title {
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
        line-height: 28px;
        margin: 13px 0 30px;
      }
      .contact {
        display: flex;
        align-items: center;
      }
      .icon {
        color: #fff;
        font-size: 26px;
        margin-right: 40px;
      }
    }
    .content-right {
      display: flex;
      img {
        width: 136px;
        height: 135px;
      }
      .right-item {
        margin-right: 37px;
      }
      .desc {
        text-align: center;
        margin-top: 11px;
      }
    }
  }
  .footer-bottom {
    margin-top: 20px;
    display: flex;
    div {
      margin-right: 50px;
    }
    a {
      color: #fff;
    }
    a:hover {
      color: @primary-color;
    }
  }

  .copyright-box {
    margin-top: 40px;
    color: #fff;
    text-align: center;
    .copyright-item {
      color: #fff;
      line-height: 20px;
      margin-left: 16px;
      .copy-right {
        vertical-align: middle;
      }
      .gongan {
        width: 16px;
        margin-right: 4px;
      }
    }
  }
}
.serviceModal .ant-modal-content {
  width: 800px !important;
}

@hack: true;@import "/data/sdic-iguopin-liuxue/src/theme.less";